.navWrapper {
  display: none;

  @media (--tablet-l) {
    display: flex;
  }

  & .navListWrapper {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    color: var(--color-championship-white);

    & .navItem {
      display: flex;
      align-items: center;
      cursor: default;
      height: 100%;

      & .navList {
        padding: var(--spacing-3);
        align-content: center;
        height: 72px;
        transition: color 0.3s ease, background 0.3s ease;

        @media (--desktop-l) {
          height: 80px;
          padding: var(--spacing-6);
        }

        & .navTitle {
          white-space: nowrap;
        }
      }

      & .navHrefList {
        padding: var(--spacing-3);
        height: 72px;
        align-content: center;
        color: var(--color-championship-white);
        text-decoration: none;

        @media (--desktop-l) {
          height: 80px;
          padding: var(--spacing-6);
        }
      }
    }
  }
}

.screenWrapper {
  visibility: hidden;
  opacity: 0;
  background: var(--color-overlay);
  height: 100vh;
  position: absolute;
  right: 0;
  left: 0;
  top: 100%;
  transition: opacity 0.3s ease, linear 0.3s;
  pointer-events: none;
}

.subNavWrapper {
  background-color: var(--color-championship-white);
  position: absolute;
  right: 0;
  top: 100%;
  left: 0;
  z-index: 1000;
  && {
    opacity: 0;
    visibility: hidden;
    padding: 0;
    width: 100vw;
    transition: opacity 0.3s ease, linear 0.3s;
    pointer-events: none;

    @media (--desktop-l) {
      width: fit-content;
    }
  }
}

.isActive {
  && {
    color: var(--color-neutral-700) !important;
    background-color: var(--color-championship-white);
  }
}

.isActiveSubNav {
  && {
    visibility: visible !important;
    opacity: 1;
    transition: opacity 0.2s ease;
    transition-delay: 0.1s;
    pointer-events: all;
  }
}
